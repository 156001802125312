$(document).ready(function() {
  let picture = $('.fil-hero__bg-image picture');
  let img = picture.find('img');
  let sources = picture.find('source');

  sources.each(function() {
    let media = $(this).attr('media');
    let srcset = $(this).attr('srcset');

    if (window.matchMedia(media).matches) {
      img.attr('src', srcset);
    }
  });
});
