let topPosition = 0;
let togglePosition = 0;
$(document).ready(function() {
  if ($(window).outerWidth() > 640) {
    $('.left-hand-nav, .left-hand-nav-pg-components').addClass('left-nav-active');
  }
  topPosition = $('#main-content').offset().top;
  $('#FixedNav').css('top', topPosition);
  togglePosition = $('#toggle_left_nav').css('top');
  $('#toggle_left_nav').on('click', function() {
    $(
      '.left-hand-nav, .left-hand-nav-pg-components, .fil-global-footer, .fil-credit-banner'
    ).toggleClass('left-nav-active');
    $(this).toggleClass('active');
  });
  $('#left_hand_nav_mobile').on('click', function() {
    $('.left-hand-nav').toggleClass('left-nav-active');
    $('.tab-for-left-hand-nav-mobile').toggleClass('is-active');
    if ($('#tab_for_left_hand_nav_mobile').hasClass('is-active')) {
      let scrollPosition = Math.round(window.scrollY);
      if (scrollPosition >= 81) {
        $('#left-nav-large').addClass('mobile-sticky-nav');
        $('#left-nav-mobile').addClass('mobile-sticky-nav');
      } else {
        $('#left-nav-large').removeClass('mobile-sticky-nav');
        $('#left-nav-mobile').removeClass('mobile-sticky-nav');
      }
      $('.tab-icon')
        .removeClass('fil-icon-chevron-d-regular')
        .addClass('fil-icon-chevron-u-regular');
      $('body, html').addClass('disable-scroll');
    } else {
      $('.tab-icon')
        .removeClass('fil-icon-chevron-u-regular')
        .addClass('fil-icon-chevron-d-regular');
      $('body, html').removeClass('disable-scroll');
    }
  });
});

$(window).scroll(function() {
  let scrollPosition = Math.round(window.scrollY);
  if (scrollPosition >= 81) {
    $('#toggle_left_nav').addClass('fixed');
    $('#FixedNav').addClass('fixed');
  } else {
    $('#toggle_left_nav').removeClass('fixed');
    $('#FixedNav').removeClass('fixed');
  }
  if ($(window).outerWidth() < 640) {
    if (scrollPosition >= 81) {
      $('#left_hand_nav_mobile').addClass('fixed');
    } else {
      $('#left_hand_nav_mobile').removeClass('fixed');
    }
  }
});

$(window).scroll(function() {
  $('#FixedNav').css('top', Math.max(0, topPosition - $(this).scrollTop()));
  $('#toggle_left_nav').css('top', Math.max(togglePosition, togglePosition - $(this).scrollTop()));
  if ($(window).outerWidth() < 640) {
    $('#left_hand_nav_mobile').css('top', Math.max(0, topPosition - $(this).scrollTop()));
  }
});
